import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ image, title, description, ...rest }) => (
  <Wrap>
    <img src={image.url} alt={title} />
    <RichText {...description} />
  </Wrap>
);
//
const Wrap = styled.div`
  text-align: justify
  display: grid;

  @media (min-width: 769px) {
    grid-template-columns: 1fr 2fr;
  }
  
  p{
    padding: 1rem;

    @media (min-width: 768px) {
      max-width: 500px;
      padding: 0 1rem;
    }
  };

  img {

    @media (min-width: 769px) {
      top: 0;
      right: 0;
      padding: 0.5rem;
      position: absolute;
      max-width: 67vw;
    }
  }
`;
